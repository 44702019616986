import { Observable } from 'rxjs';
import {
  WorkJobCreateDto,
  WorkJobDto,
  WorkJobEditDto,
  WorkJobGetAllDto,
  WorkJobPageDto,
  WorkJobPageQueryParamsDto,
} from '../dtos/workjob.dto';

export abstract class WorkJobRepository {
  abstract getPage(
    params: WorkJobPageQueryParamsDto
  ): Observable<WorkJobPageDto>;
  abstract getAll(): Observable<WorkJobGetAllDto>;
  abstract create(workJob: WorkJobCreateDto): Observable<WorkJobDto>;
  abstract update(workJob: WorkJobEditDto): Observable<WorkJobDto>;
  abstract delete(workJob: { id: number }): Observable<WorkJobDto>;
}
